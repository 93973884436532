import { NetSelectOption } from '@net-types';

export function getQuestionTranslationLabelByKey(key: string): string {
  const prefix = 'QUESTION_';
  const id = key.replace(prefix, '');
  return key.includes(prefix) ? 'onboarding.form.questions.' + id : key;
}

const questionsQuestions: string[] = [
  'QUESTION_0',
  'QUESTION_1',
  'QUESTION_2',
  'QUESTION_3',
  'QUESTION_4',
  'QUESTION_5',
  'QUESTION_6',
  'QUESTION_7',
  'QUESTION_8',
  'QUESTION_9',
  'QUESTION_10',
  'QUESTION_11',
  'QUESTION_12',
  'QUESTION_13',
  'QUESTION_14',
  'QUESTION_15',
  'QUESTION_16',
  'QUESTION_17',
  'QUESTION_18',
  'QUESTION_19',
  'QUESTION_20',
  'QUESTION_21',
  'QUESTION_22',
  'QUESTION_23',
  'QUESTION_24',
  'QUESTION_25',
  'QUESTION_26',
  'QUESTION_27',
];

export const NET_QUESTION_OPTIONS: NetSelectOption<string>[] =
  questionsQuestions.map((item) => ({
    key: item, // QUESTION_X
    label: getQuestionTranslationLabelByKey(item),
  }));
